import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { getApiKey } from "../utils/ApiHandler";
import Dataset from "./Dataset";
import ExplanationsContainer from "./ExplanationsContainer";
import Login from "./Login";
import Model from "./Model";
import "./Demo.css";
import APIKeyRenderer from "./APIKeyRenderer";

const Demo = () => {
  const [datasetDetails, setDatasetDetails] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [modelDetails, setModelDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);
        try {
          const fbToken = await user.getIdToken();
          console.log("Token: ");
          const fetchedApiKey = await getApiKey(fbToken);
          setApiKey(fetchedApiKey);
        } catch (error) {
          console.error("Error fetching API key:", error);
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false); // Set loading to false after checking authentication and fetching API key
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <div className="demo">
        <p>Loading...</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="demo">
        <Login />
      </div>
    );
  }

  if (!apiKey) {
    return (
      <div className="demo">
        <p>Fetching API key...</p>
      </div>
    );
  }

  return (
    <div className="demo">
      <div className="col md-10">
        <div className="row">
          <h1>Hello, welcome to the Decima2 demo</h1>
          <APIKeyRenderer apiKey={apiKey} />
        </div>
        <div className="row mt-10">
          <div className="col-md-6">
            <h1>Model Selection</h1>
            <Model onModelChange={setModelDetails} />
          </div>
          <div className="col-md-6">
            <h1>Dataset Selection</h1>
            <Dataset onDatasetChange={setDatasetDetails} />
          </div>
        </div>
        <div className="row">
          <ExplanationsContainer
            model={modelDetails}
            dataset={datasetDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default Demo;
