import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Authors from "./Authors";
import Sidebar from "./Sidebar";
import "./BlogPost.css";
import "bootstrap/dist/css/bootstrap.min.css";

const BlogPost = ({ post }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/blog/${post}.json`);
        if (!response.ok) {
          throw new Error("Failed to fetch blog post");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setError(error);
      }
    };

    fetchPost();
  }, [post]);

  if (error) {
    return <div className="alert alert-danger">Error: {error.message}</div>;
  }

  if (!data) {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const components = {
    img: ({ alt, src }) => <img src={src} alt={alt} className="img-fluid" />,
  };

  return (
    <div className="blog container">
      <div className="row">
        <div className="col-md-8">
          <h1 className="blog-post-title">{data.title}</h1>
          <p className="text-muted">{data.date}</p>
          <Authors authors={data.authors} />
          <ReactMarkdown components={components}>{data.content}</ReactMarkdown>
        </div>
        <div className="col-md-4">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
