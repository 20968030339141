import React, { useEffect, useState } from "react";
import { getById } from "../utils/ApiHandler";
import "bootstrap/dist/css/bootstrap.min.css";

const Overview = ({ selectedObjectId, objectType, onObjectDetailsFetched }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchObjectDetails = async () => {
      console.log(
        "selectedObjectId: ",
        selectedObjectId,
        "objectType: ",
        objectType
      );
      if (selectedObjectId) {
        try {
          const details = await getById(objectType, selectedObjectId);
          setDetails(details);
          onObjectDetailsFetched(details);
        } catch (error) {
          console.error("Error fetching object details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchObjectDetails();
  }, [selectedObjectId, objectType, onObjectDetailsFetched]);

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  return (
    <div>
      {details ? (
        Object.entries(details).map(([key, value]) => (
          <div key={key} className="mb-3">
            <h2>{key.charAt(0).toUpperCase() + key.slice(1)}</h2>
            <h1>{value}</h1>
          </div>
        ))
      ) : (
        <p>No details available.</p>
      )}
    </div>
  );
};

export default Overview;
