import React, { useState } from "react";
import { getExplanation } from "../utils/ApiHandler"; // Assuming apiHandler.js exports getExplanation

const Output = ({ modelId, datasetId, feature, value }) => {
  const [explanation, setExplanation] = useState(null);

  const handleGetExplanation = async () => {
    console.log(
      "modelId: ",
      modelId,
      " datasetId: ",
      datasetId,
      " feature: ",
      feature,
      " value: ",
      value
    );
    try {
      const result = await getExplanation(modelId, datasetId, feature, value);
      setExplanation(result);
    } catch (error) {
      console.error("Error fetching explanation:", error);
    }
  };

  return (
    <div>
      <h1>Explanation Container</h1>
      <button onClick={handleGetExplanation}>Get Explanation</button>
      {explanation && (
        <div>
          <h2>Explanation Result:</h2>
          <pre>{JSON.stringify(explanation, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Output;
