import React, { useState, useEffect } from "react";
import { getList } from "../utils/ApiHandler";

const ListDropdown = ({ objectType, onObjectChange }) => {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const options = [{ id: "", name: "Select an option" }];
      try {
        const list = await getList(objectType);
        if (list) {
          options.push(...list);
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          setError("Sorry, there is a server error, please report this error.");
        } else {
          console.error("Error fetching list:", error);
        }
      }
      options.push({ id: "upload", name: "Upload" });
      console.log("options", options);

      setOptions(options);
    };

    fetchData();
  }, [objectType]);

  const handleChange = (event) => {
    console.log("event.target: ", event.target.value);
    onObjectChange(event.target.value);
  };

  return (
    <div>
      {error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <select className="form-select" onChange={handleChange}>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default ListDropdown;
