import React, { useState } from "react";
import Conversation from "./Conversation";

const industryData = {
  Diagnosis: {
    blackBoxOutput: "Heart risk disease prediction for patient_1234 = 0.75",
    responses: [
      {
        question: "Why wasn't the output higher?",
        response:
          "For the heart risk disease to be higher, blood_pressure would need to increase by 6%.",
      },
      {
        question: "What factors contributed to the result?",
        response:
          "The main factors were age, cholesterol levels, and smoking habits.",
      },
      {
        question: "How can the prediction be lowered?",
        response:
          "Improving diet, increasing physical activity, and reducing stress can help lower the prediction",
      },
      {
        question: "What data was used for the analysis?",
        response:
          "The analysis used patient medical records, including blood tests and lifestyle questionnaires.",
      },
      {
        question: "Can I trust this prediction?",
        response:
          "The prediction has a robustness of 80% - 80% of similar individuals would receive the same outcome  - would you like to learn more?",
      },
    ],
  },
  "Credit scoring": {
    blackBoxOutput: "Credit_score for applicant_5678 = 720",
    responses: [
      {
        question: "Why wasn't the score higher?",
        response:
          "For the credit score to be higher, income would need to increase by 10%.",
      },
      {
        question: "What factors contributed to the result?",
        response:
          "The main factors were payment history, credit utilization, and length of credit history.",
      },
      {
        question: "How can the score be lowered?",
        response:
          "Paying off outstanding debts, reducing age, and maintaining a good payment history can help lower the score.",
      },
      {
        question: "Age cannot be actioned so should not be used by the model",
        response: "We are updating the model accordingly",
      },
      {
        question: "How accurate is the model?",
        response:
          "The prediction has an accuracy of 90%, based on the provided test set, but has quite significant differences between data groups - would you like to learn more?",
      },
    ],
  },
  "Workflow Automation": {
    blackBoxOutput: "Recommending destination for email_245= billing",
    responses: [
      {
        question: "Why wasn't this email sent to marketing?",
        response:
          "For the email to be sent to marketing then it would have to reference ‘adverts’.",
      },
      {
        question: "What words contributed to this categorisation?",
        response:
          "The most influential words were ‘invoice’, ‘payment’ and ‘account’",
      },
      {
        question: "How influential was the word “please” in the email?",
        response:
          "This word actually lowered the likelihood of “billing” increasing the likelihood of “marketing”",
      },
      {
        question:
          "The word “please” should  not influence email categorisation",
        response: "Adjusting model accordingly",
      },
    ],
  },
};

const IndustryConversation = () => {
  const [selectedIndustry, setSelectedIndustry] = useState("Diagnosis");

  const handleIndustryClick = (industry) => {
    setSelectedIndustry(null); // Reset selectedIndustry to unmount Conversation
    setTimeout(() => setSelectedIndustry(industry), 0); // Set new industry after a short delay
  };

  return (
    <div className="row align-items-start my-5 main-row">
      <div className="col-md-4">
        <h5 className="mt-4">
          Find out how to bring together AI and end user in your industry
        </h5>
        <div className="btn-group-vertical mt-3 w-100" role="group">
          {Object.keys(industryData).map((industry) => (
            <button
              key={industry}
              className={`btn btn-secondary ${
                selectedIndustry === industry ? "active" : ""
              }`}
              onClick={() => handleIndustryClick(industry)}
            >
              {industry}
            </button>
          ))}
        </div>
      </div>
      <div className="col-md-8">
        {selectedIndustry && industryData[selectedIndustry] && (
          <Conversation
            blackBoxOutput={industryData[selectedIndustry].blackBoxOutput}
            responses={industryData[selectedIndustry].responses}
          />
        )}
      </div>
    </div>
  );
};
export default IndustryConversation;
