import React, { useState } from "react";
import SpeechBubble from "./SpeechBubble";
import ButtonGroup from "./ButtonGroup";
import QuestionAnswer from "./QuestionAnswer";

const Conversation = ({ blackBoxOutput, responses }) => {
  const [renderDecima2Prompt, setRenderDecima2Prompt] = useState(false);
  const [renderQuestions, setRenderQuestions] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(null);
  console.log("responses:", responses);

  const handleButtonClick = (response) => {
    setCurrentResponse(null); // Reset currentResponse to unmount QuestionAnswer
    setTimeout(() => setCurrentResponse(response), 0); // Set new response after a short delay
    console.log("response:", response);
  };

  return (
    <div className="container mt-4">
      <SpeechBubble
        text={blackBoxOutput}
        from="Black Box Model"
        onComplete={() => setRenderDecima2Prompt(true)}
      />
      {renderDecima2Prompt && (
        <SpeechBubble
          text="What would you like to understand?"
          from="DECIMA2"
          onComplete={() => setRenderQuestions(true)}
        />
      )}
      {renderQuestions && (
        <ButtonGroup
          handleButtonClick={handleButtonClick}
          responses={responses}
        />
      )}
      {currentResponse && (
        <QuestionAnswer
          question={currentResponse.question}
          answer={currentResponse.response}
        />
      )}
    </div>
  );
};

export default Conversation;
