import React, { useState } from "react";
import "./ButtonGroup.css";

const ButtonGroup = ({ handleButtonClick, responses = [] }) => {
  const [clickedButton, setClickedButton] = useState(null);

  const handleClick = (response) => {
    setClickedButton(response.question);
    handleButtonClick(response);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="d-flex flex-wrap justify-content-left">
            {responses.map((response) => (
              <div key={response.question} className="p-2">
                <button
                  className={`btn btn-custom ${
                    clickedButton === response.question ? "active" : ""
                  }`}
                  onClick={() => handleClick(response)}
                  disabled={clickedButton === response.question}
                >
                  {response.question}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonGroup;
