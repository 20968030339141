import React, { useState } from "react";
import ModelAnimation from "./ModelAnimation";
import Input from "./Input";
import Output from "./Output";

const ExplanationsContainer = ({ model, dataset }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedFeature, setSelectedFeature] = useState("");

  const handleInput = (selectedFeature, inputValue) => {
    console.log(
      "dataset: ",
      dataset,
      " selectedFeature: ",
      selectedFeature,
      " inputValue: ",
      inputValue
    );
    setSelectedFeature(selectedFeature);
    setInputValue(inputValue);
  };

  console.log("model", model);
  console.log("dataset", dataset);

  return (
    <div className="row mt-6">
      <div className="col-md-2">
        <Input
          dataset={dataset}
          OnFeatureChange={setSelectedFeature}
          OnInputChange={setInputValue}
        />
      </div>
      <div className="col-md-2">
        {model ? <ModelAnimation model={model} /> : <p>Loading model...</p>}
      </div>
      <div className="col-md-8">
        {model && dataset ? (
          <Output
            modelId={model.id}
            datasetId={dataset.id}
            feature={selectedFeature}
            value={inputValue}
          />
        ) : (
          <p>Loading data...</p>
        )}
      </div>
    </div>
  );
};

export default ExplanationsContainer;
