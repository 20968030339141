import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Home from "./home/Home";
import Demo from "./demo/Demo";
import Docs from "./docs/Docs";
import NavBar from "./NavBar";
import Login from "./demo/Login";
import Blog from "./blog/Blog";
import BlogPost from "./blog/BlogPost"; // Import BlogPost
import "./App.css";

const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/login" element={<Login />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:post" element={<BlogPostWrapper />} />
      </Routes>
    </Router>
  );
};

const BlogPostWrapper = () => {
  const { post } = useParams();
  console.log(`Post: ${post} type: ${typeof post}`);
  return <BlogPost post={post} />;
};

export default App;
