import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sidebar.css";

const Sidebar = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("/blog/index.json");
        if (!response.ok) {
          throw new Error("Failed to fetch posts list");
        }
        const jsonData = await response.json();
        setPosts(jsonData);
      } catch (error) {
        console.error("Error fetching posts list:", error);
        setError(error);
      }
    };

    fetchPosts();
  }, []);

  if (error) {
    return <div className="alert alert-danger">Error: {error.message}</div>;
  }

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h3 className="sidebar-title">Blog Posts</h3>
      </div>
      <div className="post-list">
        {posts.map((post) => (
          <div key={post.id} className="post-item">
            <Link to={`/blog/${post.id}`} className="post-link">
              {post.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
