import React, { useState } from "react";
import { postObject } from "../utils/ApiHandler";
import "bootstrap/dist/css/bootstrap.min.css";

const UploadModel = () => {
  const [modelName, setModelName] = useState("");
  const [modelDescription, setModelDescription] = useState("");
  const [modelFile, setModelFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!modelFile) {
      alert("Please upload a CSV file.");
      return;
    }

    const formData = {
      modelName,
      modelDescription,
      model: modelFile,
    };

    try {
      const response = await postObject("model", formData);
      console.log("Upload successful:", response);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      <div className="mb-3">
        <label className="form-label">Model Name:</label>
        <input
          type="text"
          className="form-control"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Model Description:</label>
        <textarea
          className="form-control"
          value={modelDescription}
          onChange={(e) => setModelDescription(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Upload pickle File:</label>
        <input
          type="file"
          className="form-control"
          accept=".pkl"
          onChange={(e) => setModelFile(e.target.files[0])}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Upload
      </button>
    </form>
  );
};

export default UploadModel;
