import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Input = ({ dataset, OnFeatureChange, OnInputChange }) => {
  if (!dataset) {
    return <p>Please select a dataset to call an input from</p>;
  }

  if (!dataset.columns) {
    return <p>Dataset columns are not available</p>;
  }

  const options = [{ id: "", name: "Select an option" }];
  dataset.columns.map((feature) =>
    options.push({ id: feature, name: feature })
  );

  return (
    <div className="container mt-3">
      <select
        className="form-select"
        onChange={(e) => OnFeatureChange(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <div className="form-group mt-3">
        <input
          id="inputValue"
          type="text"
          className="form-control"
          onChange={(e) => OnInputChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Input;
