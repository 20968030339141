import React from "react";
import SwaggerUI from "swagger-ui-react";
import "./Docs.css";
import "swagger-ui-react/swagger-ui.css";

const APIDocs = () => {
  return (
    <div className="docs">
      <div className="swagger-ui-wrapper">
        <SwaggerUI url="/v1.yaml" />
      </div>
    </div>
  );
};

export default APIDocs;
