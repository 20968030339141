import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Authors from "./Authors"; // Import the Authors component
import "./Blog.css"; // Custom CSS for blog styles

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("/blog/index.json");
        if (!response.ok) {
          throw new Error("Failed to fetch posts list");
        }
        const jsonData = await response.json();
        setPosts(jsonData);
        console.log("posts", posts); // Log the fetched posts to the console
      } catch (error) {
        console.error("Error fetching posts list:", error);
        setError(error);
      }
    };

    fetchPosts();
  }, []);

  if (error) {
    return <div className="alert alert-danger">Error: {error.message}</div>;
  }

  return (
    <div className="blog">
      <header className="blog-header">
        <h1>Our Blog</h1>
        <p className="subheader">Latest updates and insights from the team</p>
      </header>
      <div className="col md-6">
        {posts.map((post) => (
          <Link
            to={`/blog/${post.id}`}
            key={post.id}
            className="blog-post-link"
          >
            <div className="blog-post">
              <div className="blog-post-header">
                <h1 className="blog-post-title">{post.title}</h1>
                <p className="blog-post-summary">{post.summary}</p>
              </div>
              <Authors authors={post.authors} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blog;
