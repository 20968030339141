import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./Home.css"; // Ensure you import the CSS file

import IndustryConversation from "./IndustryConversation";
import WaitingList from "./WaitingList";
import FAQ from "./FAQ";

const Home = () => {
  return (
    <div className="home container">
      <div className="row align-items-center my-5 main-row">
        <div className="col-md-8 text-left">
          {" "}
          {/* Align text to the left */}
          <h1 className="main-header display-4">Human in the loop AI</h1>
          <h2 className="sub-header lead">Improve AI with human insight</h2>
        </div>
        <div className="col-md-4 text-center"></div>
      </div>
      <IndustryConversation />
      <FAQ /> <WaitingList />{" "}
    </div>
  );
};

export default Home;
