import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SpeechBubble.css";

const SpeechBubble = ({ text, from, onComplete }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex(index + 1);
      }, 25);
      return () => clearTimeout(timeout);
    } else if (onComplete) {
      onComplete();
    }
  }, [index, text, onComplete]);

  const className = from.toLowerCase();

  return (
    <div className={`card speech-bubble ${className}`}>
      <div className="card-body">
        <h5 className={`card-title ${className}`}>{from}</h5>
        <p className="card-text">{displayedText}</p>
      </div>
    </div>
  );
};

export default SpeechBubble;
