import React, { useState } from "react";
import SpeechBubble from "./SpeechBubble";

const QuestionAnswer = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <div>
      <SpeechBubble
        text={question}
        from="User"
        onComplete={() => setShowAnswer(true)}
      />
      {showAnswer && <SpeechBubble text={answer} from="DECIMA2" />}
    </div>
  );
};

export default QuestionAnswer;
