// WaitingList.js
import React, { useState } from "react";
import { subscribeToNewsletter } from "../utils/ApiHandler"; // Import the API function

import "./WaitingList.css"; // Ensure you import the CSS file

const WaitingList = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await subscribeToNewsletter({
        firstName: name.split(" ")[0],
        lastName: name.split(" ")[1] || "",
        email: email,
      });
      setMessage("Form submitted successfully!");
    } catch (error) {
      setMessage("Failed to submit the form. Please try again.");
    }
  };

  return (
    <div className="row justify-content-center align-items-center main-row">
      <div className="col-md-9">
        <div className="card mt-0 p-3">
          <div className="card-body">
            <h3 className="card-title text-center mb-4">
              Join the Newsletter for product updates
            </h3>
            <div className="row justify-content-center align-items-center">
              <div className="col-md-9">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your full name"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="form-group form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="consentCheckbox"
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="consentCheckbox"
                    >
                      I consent to receive marketing material, I can unsubscribe
                      at any time
                    </label>
                  </div>
                  <button type="submit" className="btn btn-custom w-100">
                    Subscribe
                  </button>
                </form>
                {message && <p className="mt-3 text-center">{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingList;
