import React, { useState } from "react";
import { postObject } from "../utils/ApiHandler";
import "bootstrap/dist/css/bootstrap.min.css";

const UploadDataset = () => {
  const [datasetName, setdatasetName] = useState("");
  const [datasetDescription, setdatasetDescription] = useState("");
  const [datasetFile, setdatasetFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!datasetFile) {
      alert("Please upload a CSV file.");
      return;
    }

    const formData = {
      datasetName,
      datasetDescription,
      dataset: datasetFile,
    };

    try {
      const response = await postObject("dataset", formData);
      console.log("Upload successful:", response);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      <div className="mb-3">
        <label className="form-label">Dataset Name:</label>
        <input
          type="text"
          className="form-control"
          value={datasetName}
          onChange={(e) => setdatasetName(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Dataset Description:</label>
        <textarea
          className="form-control"
          value={datasetDescription}
          onChange={(e) => setdatasetDescription(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Upload csv File:</label>
        <input
          type="file"
          className="form-control"
          accept=".csv"
          onChange={(e) => setdatasetFile(e.target.files[0])}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Upload
      </button>
    </form>
  );
};

export default UploadDataset;
