import React, { useState } from "react";
import ListDropdown from "./ListDropdown";
import Overview from "./Overview";
import Upload from "./UploadModel";

const Model = ({ onModelChange }) => {
  const [selectedModel, setSelectedModel] = useState(null);

  const handleModelChange = (model) => {
    setSelectedModel(model);
    console.log("Model: ", model);
    onModelChange(model);
  };

  return (
    <div>
      <h2>Choose the Model:</h2>
      <ListDropdown objectType="model" onObjectChange={handleModelChange} />
      {selectedModel && selectedModel === "upload" ? (
        <Upload />
      ) : (
        selectedModel && (
          <div>
            <h2>Model Information:</h2>
            <Overview
              selectedObjectId={selectedModel}
              objectType="model"
              onObjectDetailsFetched={onModelChange}
            />
          </div>
        )
      )}
    </div>
  );
};

export default Model;
