import React, { useState, useEffect } from "react";
import ListDropdown from "./ListDropdown";
import Overview from "./Overview";
import Upload from "./UploadDataset";

const Dataset = ({ onDatasetChange }) => {
  const [selectedDataset, setSelectedDataset] = useState(null);

  const handleDatasetChange = (dataset) => {
    setSelectedDataset(dataset);
    onDatasetChange(dataset);
  };

  useEffect(() => {
    if (selectedDataset) {
      console.log("Updated selectedDataset: ", selectedDataset);
    }
  }, [selectedDataset]);

  return (
    <div>
      <h2>Choose the Dataset:</h2>
      <ListDropdown objectType="dataset" onObjectChange={handleDatasetChange} />
      {selectedDataset === "upload" ? (
        <Upload />
      ) : (
        selectedDataset && (
          <div>
            <h2>Dataset Information:</h2>
            <Overview
              selectedObjectId={selectedDataset}
              objectType="dataset"
              onObjectDetailsFetched={onDatasetChange}
            />
          </div>
        )
      )}
    </div>
  );
};

export default Dataset;
