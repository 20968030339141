import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import "./FAQ.css"; // Import the custom CSS file

const questions = [
  {
    question: "How does it work?",
    answer:
      "We use causal probes to query the black box model, and use the responses and a reasoning algorithm to generate explanations. These are then converted into human understandable language. Our explanations are designed to be easy to understand and actionable. The user can then feedback into the model to improve the model.",
  },
  {
    question: "What is required to get started?",
    answer:
      "You just need to upload your model and dataset, and we will take care of the rest.",
  },
  {
    question: "How do I integrate the explanations into my end user portal?",
    answer:
      "All of our explanations are built API first, so you can easily integrate them into your existing portal.",
  },
  {
    question: "Will it work with my model?",
    answer: "As long as you have a model with inputs and outputs.",
  },
];

const FAQ = () => {
  const [openQuestions, setOpenQuestions] = useState([]);

  const handleToggle = (index) => {
    setOpenQuestions((prevOpenQuestions) =>
      prevOpenQuestions.includes(index)
        ? prevOpenQuestions.filter((i) => i !== index)
        : [...prevOpenQuestions, index]
    );
  };

  return (
    <div className="row align-items-center my-5 main-row">
      <h2>Frequently Asked Questions</h2>
      {questions.map((question, index) => (
        <div key={index}>
          <button
            className={`question ${
              openQuestions.includes(index) ? "" : "collapsed"
            }`}
            onClick={() => handleToggle(index)}
            aria-expanded={openQuestions.includes(index)}
          >
            {question.question}
          </button>
          {openQuestions.includes(index) && (
            <div className="answer">{question.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
