import React from "react";
import "./Authors.css";

const Authors = ({ authors }) => {
  const authorDetails = {
    Alexis: {
      fullName: "Alexis Monks",
      position: "CEO @ Decima2",
      image: "/img/blog/alexis.png",
      social: {
        linkedin:
          "https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg",
        github:
          "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg",
      },
    },
    Torty: {
      fullName: "Dr Torty Sivill",
      position: "CTO/CSO @ Decima2",
      image: "/img/blog/torty.png",
      social: {
        linkedin:
          "https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg",
        github:
          "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg",
      },
    },
    // More authors can be added here
  };

  return (
    <div className="author-cards-container">
      {authors.map((author) => {
        // Check if the author exists in the authorDetails object
        const details = authorDetails[author];

        // If no details found for the author, skip rendering
        if (!details) {
          return null;
        }

        return (
          <div key={author} className="author-card">
            <div className="image-container">
              <img
                src={details.image}
                alt={`${details.fullName}'s profile`}
                className="rounded-circle author-image"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title author-name">{details.fullName}</h5>
              <p className="card-position">{details.position}</p>
              <div className="social-icons">
                {details.social.linkedin && (
                  <a
                    href={details.social.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${details.fullName}'s LinkedIn`}
                  >
                    <img
                      src={details.social.linkedin}
                      alt="LinkedIn"
                      className="social-icon"
                    />
                  </a>
                )}
                {details.social.github && (
                  <a
                    href={details.social.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${details.fullName}'s GitHub`}
                  >
                    <img
                      src={details.social.github}
                      alt="GitHub"
                      className="social-icon"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Authors;
