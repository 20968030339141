import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ModelAnimation.css"; // Assuming you have a CSS file for custom styles

const ModelAnimation = ({ model }) => {
  console.log("ModelAnimation: ", model);
  return (
    <div className="model-animation-container">
      {model === "upload" ? (
        <p>Upload a model</p>
      ) : (
        <>
          <div className="animation">
            {/* Placeholder for the animation */}
            <img
              src="img/blackbox.gif"
              className="img-fluid"
              alt="Model Animation"
            />
          </div>
          <div className="model-name-overlay">
            <h3>{model && model.name ? model.name : "Model Name"}</h3>
          </div>
        </>
      )}
    </div>
  );
};

export default ModelAnimation;
