const url = process.env.REACT_APP_API_URL;
const hubspotPortalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
const hubspotNewsletterFormId =
  process.env.REACT_APP_HUBSPOT_NEWSLETTER_FORM_ID;
let apiKey = null;

export const getList = async (objectType) => {
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", `${apiKey}`);
  console.log(
    `calling getList with ObjectType: ${objectType} and apiKey: ${getApiKey()}`
  );
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${url}/${objectType}s`, requestOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
  if (objectType === "model") {
    return [
      {
        id: 1,
        name: "churn_model_1",
      },
      {
        id: 2,
        name: "churn_model_1",
      },
      {
        id: 3,
        name: "churn_model_1",
      },
      {
        id: 4,
        name: "churn_model_1",
      },
      {
        id: 5,
        name: "churn_model_1",
      },
      {
        id: 6,
        name: "churn_model_1",
      },
      {
        id: 7,
        name: "churn_model_1",
      },
      {
        id: 8,
        name: "churn_model_1",
      },
      {
        id: 9,
        name: "churn_model_1",
      },
      {
        id: 10,
        name: "churn_model_1",
      },
      {
        id: 11,
        name: "churn_model_1",
      },
      {
        id: 12,
        name: "churn_model_1",
      },
      {
        id: 13,
        name: "churn_model_1",
      },
      {
        id: 14,
        name: "churn_model_1",
      },
      {
        id: 15,
        name: "churn_model_1",
      },
      {
        id: 16,
        name: "churn_model_1",
      },
      {
        id: 17,
        name: "churn_model_1",
      },
      {
        id: 18,
        name: "churn_model_1",
      },
      {
        id: 19,
        name: "churn_model_1",
      },
      {
        id: 20,
        name: "churn_model_1",
      },
      {
        id: 21,
        name: "churn_model_1",
      },
      {
        id: 22,
        name: "churn_model_1",
      },
      {
        id: 23,
        name: "churn_model_1",
      },
      {
        id: 24,
        name: "churn_model_1",
      },
      {
        id: 25,
        name: "churn_model_1",
      },
    ];
  }
  if (objectType === "dataset") {
    return [
      {
        id: 1,
        name: "customers",
      },
      {
        id: 2,
        name: "customers",
      },
    ];
  }
};

export const getById = async (objectType, objectId) => {
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", `${apiKey}`);
  console.log(
    `calling getById with ObjectType: ${objectType} and objectId: ${objectId}`
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${url}/${objectType}/${objectId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log("data: $(data)");
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};
export const getExplanation = async (modelId, datasetId, feature, value) => {
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", `${apiKey}`);
  console.log(
    `calling getExplanation with outcome?model_id=${modelId}&dataset_id=${datasetId}&$feature=${feature}&value=${value}`
  );
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${url}/explanation/outcome?model_id=${modelId}&dataset_id=${datasetId}&feature=${feature}&value=${value}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const postObject = async (objectType, object) => {
  const myHeaders = new Headers();
  myHeaders.append("x-api-key", `${apiKey}`);

  console.log(
    `calling postObject with objectType = ${objectType} and object = ${JSON.stringify(
      object
    )} and apiKey = ${getApiKey()}`
  );

  const formData = new FormData();
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      formData.append(key, object[key]);
    }
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData, // Add formData to request body
    redirect: "follow",
  };

  try {
    const response = await fetch(`${url}/${objectType}`, requestOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const subscribeToNewsletter = async ({
  email,
  firstname = "",
  lastname = "",
}) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotNewsletterFormId}`;

  console.log(
    `calling subscribeToNewsletter with email = ${email} firstname = ${firstname} lastname = ${lastname}`
  );

  const body = JSON.stringify({
    fields: [
      { name: "email", value: email },
      { name: "firstname", value: firstname },
      { name: "lastname", value: lastname },
      { name: "newsletter", value: true },
    ],
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I consent to data processing.",
      },
    },
  });

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  };

  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data;
};

export const getApiKey = async (fbToken) => {
  if (apiKey) {
    return apiKey;
  }
  if (!apiKey && fbToken) {
    try {
      const auth = "Firebase-Token " + fbToken;
      console.log("auth: ", auth);

      const response = await fetch(`${url}/api_key`, {
        method: "GET",
        headers: {
          Authorization: auth,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("data: ", data);
      apiKey = data.api_key;
      return apiKey;
    } catch (error) {
      console.error("Error fetching API key:", error);
      throw error;
    }
  }
};
